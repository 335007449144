<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text">Training Video</span>
      </v-card-title>
      <div class="d-flex justify-center ma-2">
        <video
          class="video pa-2 elevation-2 rounded"
          :src="require('@/assets/test-vid.mp4')"
          controls
        ></video>
      </div>
      <v-container>
        <v-row>
          <v-col cols="10" class="mx-auto px-8 py-6">
            <div class="pa-2 elevation-2 rounded">
              <span
                class="bg-gradient-primary-2 white--text pa-2 font-weight-bold text-lg rounded elevation-2"
              >
                Content
              </span>
              <div class="content mt-3">
                <div>1: Intro Quiz</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Done" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'

export default {
  name: 'ViewVideoModal',
  components: {
    AcButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogVisible: false,
      quizExpand: null,
    }
  },
  methods: {
    onClose() {
      this.quizExpand = null
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  max-width: 600px;
}
</style>
