<template>
  <div>
    <ac-module-heading> Available Videos </ac-module-heading>
    <v-card>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="5">
            <div class="d-flex">
              <ac-select
                label="Company"
                v-model="selectedCompany"
                :items="companies"
                class="company-select me-3"
              />
              <ac-select
                label="Users"
                v-model="selectedUser"
                :items="users"
                class="company-select me-3"
                @input="generateTable"
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <ac-button title="Create" :icon="icons.mdiPlus"></ac-button>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="tableHeader"
          :items="tableData"
          :loading="loading"
          class="text-no-wrap"
        >
          <template #[`item.actions`]>
            <ac-icon-btn btn-class="me-2" text="Edit" :icon="icons.mdiPencil" color="primary" />
            <template v-if="allUsers">
              <ac-icon-btn
                text="View"
                :icon="icons.mdiEye"
                color="success"
                @click="modalVisible = true"
              />
            </template>
            <template v-else>
              <ac-icon-btn
                btn-class="me-2"
                text="View History"
                :icon="icons.mdiArchiveEye"
                color="info"
              />
              <ac-icon-btn
                text="Mark as Complete"
                :icon="icons.mdiCheckboxMarkedCircleOutline"
                color="success"
              />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <view-video-modal v-model="modalVisible" />
  </div>
</template>
<script>
import AcButton from '@/components/AcButton.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import ViewVideoModal from '@/components/AvailableVideos/ViewVideoModal.vue'
import { mdiPlus, mdiPencil, mdiEye, mdiArchiveEye, mdiCheckboxMarkedCircleOutline } from '@mdi/js'

export default {
  components: {
    AcButton,
    AcSelect,
    AcIconBtn,
    ViewVideoModal,
  },
  data: () => ({
    modalVisible: false,
    icons: { mdiPlus, mdiPencil, mdiEye, mdiArchiveEye, mdiCheckboxMarkedCircleOutline },
    selectedCompany: 'All Companies',
    companies: ['All Companies', 'Company 1', 'Company 2', 'Company 3'],
    selectedUser: 0,
    users: [
      {
        label: 'All Users',
        value: 0,
      },
      {
        label: 'User 1',
        value: 1,
      },
      {
        label: 'User 2',
        value: 2,
      },
      {
        label: 'User 3',
        value: 3,
      },
    ],

    // * table
    loading: false,
    tableHeader: [
      { text: 'NAME', value: 'name' },
      { text: 'DESCRIPTION', value: 'desc' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    tableData: [
      {
        name: '2020 AML Training',
        desc: 'AML Training',
        status: 'Not Yet Watched',
      },
      {
        name: "Gabby's automated test quiz",
        desc: '	Test video mock created',
        status: 'Not Yet Watched',
      },
    ],
  }),
  computed: {
    allUsers() {
      return this.selectedUser === 0
    },
  },
  methods: {
    generateTable(userId) {
      if (userId) {
        this.tableHeader = [
          { text: 'NAME', value: 'name' },
          { text: 'DESCRIPTION', value: 'desc' },
          { text: 'STATUS', value: 'status' },
          {
            text: 'ACTIONS',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ]
      } else {
        this.tableHeader = [
          { text: 'NAME', value: 'name' },
          { text: 'DESCRIPTION', value: 'desc' },
          {
            text: 'ACTIONS',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
  },
  created() {
    this.generateTable(0)
  },
}
</script>

<style lang="scss" scoped></style>
